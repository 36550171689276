import React, { useState } from 'react'
import { Link } from 'react-router-dom'

const Sidebar = ({ cats }) => {
    const [mainToggle, setMainToggle] = useState(null);
    const [parentToggle, setParentToggle] = useState(null);
    const [childToggle, setChildToggle] = useState(null);

    let mainCatStyle = {
        backgroundColor: "#0d1117",
        border: "none",
        fontSize: "20px",
        textDecoration: "overline"
    }

    let parentCatStyle = {
        backgroundColor: "#0d1117",
        border: "none",
        fontSize: "15px",
        marginTop: "0px",
        marginBottom: "0px",
        paddingLeft: "25px"
    }
    let childCatStyle = {
        backgroundColor: "#0d1117",
        border: "none",
        fontSize: "15px",
        marginTop: "0px",
        marginBottom: "0px",
        paddingLeft: "35px"
    }


    const childCat = (parentCat) => {
        let childCat = []
        cats.map(cat => (
            cat.power === 3 && cat.mainCategory === mainToggle && cat.parentCategory === parentCat && (
                childCat.push(cat)
            )
        ))
        childCat.sort((a, b) => a.childCategory.localeCompare(b.childCategory))
        const child = childCat.map(cat => (
            <>
                <p style={childCatStyle}><Link to={`/blog/${cat.slug}`}>{cat.childCategory}</Link></p>
            </>
        ))
        return child
    }

    const parentCat = (mainCat) => {
        let parentsCat = []
        cats.map(cat => (
            cat.power === 2 && cat.mainCategory === mainCat && (
                parentsCat.push(cat.parentCategory)
            )
        ))
        parentsCat.sort()
        const parent = parentsCat.map(cat => (
            <>
                <button className='btn btn-primary' style={parentCatStyle} onClick={(e) => setParentToggle(cat)}>
                    - {cat}
                </button>
                <br />
                {parentToggle === cat && childCat(parentToggle)}
            </>
        ))
        return parent
    }

    const sideCat = (cats) => {
        const cat = cats.map(cat => (
            cat.power === 1 && (
                <>
                    {/* <hr style={{ margin: "0px", backgroundColor: "#8266ac89" }} /> */}
                    <button className="btn btn-dark" style={mainCatStyle} onClick={(e) => setMainToggle(cat.mainCategory)}>
                        {cat.mainCategory}
                    </button>
                    <br />
                    {mainToggle === cat.mainCategory && parentCat(mainToggle)}
                </>
            )
        ))
        return cat
    }

    return (
        <div className="primary-sidebar clearfix">
            <div className="sidebar-masonary row">
                <div className="col-lg-12 col-sm-6 widget popular-articles">
                    <h5 className="widget-title">Popular Articles</h5>
                    <div className="articles">
                        <div className="">
                            <div className="">
                                {sideCat(cats)}
                                <span className="post-date"></span>
                            </div>
                        </div>
                    </div >
                </div >
            </div >
        </div >

    )
}

export default Sidebar