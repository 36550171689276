import React, { useState, useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { Link, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { blogsByChildCategory } from '../../redux/actions/blogActions'
import Footer from '../Layout/Footer'
import Loader from '../Layout/Loader'
import { Header } from '../Layout/Header'
import Post from '../Layout/Post'
import Sidebar from '../Layout/Sidebar'
import { getAllCategories } from '../../redux/actions/categoryActions'
import HomeIcon from '@mui/icons-material/Home';

const BlogsByCategory = () => {
    const dispatch = useDispatch()
    const { main, parent, child } = useParams()

    const blogsByCat = useSelector(state => state.blogsByChildCategory)
    const { loading, blogs, success } = blogsByCat
    const getCat = useSelector(state => state.getCategories)
    const { loading: catLoading, categories } = getCat


    useEffect(() => {
        dispatch(blogsByChildCategory({ childCat: `${main}/${parent}/${child}` }))
        dispatch(getAllCategories({ "power": 1 }))
        window.scrollTo(0, 0)
    }, [child])

    return (
        <>
            <Helmet>
                <meta charset="UTF-8" />
                <meta http-equiv="X-UA-Compatible" content="IE=edge" />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <meta name="format-detection" content="telephone=no" />
                <meta name='robots' content='index, follow, max-image-preview:large, max-snippet:-1, max-video-preview:-1' />
                <link rel="canonical" href="https://pwoly.com/" />
                <meta property="og:locale" content="en_US" />
                <meta property="og:type" content="website" />

                <title>Pwoly- A Tech Blog</title>
                <meta name="description" content="Browse the updated news and dev tools from pwoly.com, the best blog platform with tons of dev news and solutions for all programming stacks." />
                <meta name="keywords" content="programming news, programmers blog, blogs for programmers, best programming blog, dev blogs, tech news" />
                <meta property="og:title" content="Pwoly- A Tech Blog" />
                <meta property="og:description"
                    content="Browse the updated news and dev tools from pwoly.com, the best blog platform with tons of dev news and solutions for all programming stacks." />
                <meta property="og:type" content="Website" />
                <meta property="og:url" content="https://pwoly.com/" />
                <meta property="og:image" content="https://pwoly.com/assets/img/banner/1.jpg" />
                <meta property="og:image:width" content="1280" />
                <meta property="og:image:height" content="720" />

                <meta name="twitter:title" content="Pwoly- A Tech Blog" />
                <meta name="twitter:description"
                    content="Browse the updated news and dev tools from pwoly.com, the best blog platform with tons of dev news and solutions for all programming stacks." />
                <meta name="twitter:image" content="https://pwoly.com/assets/img/banner/1.jpg" />
                <meta name="twitter:card" content="Website" />

                <meta property="og:site_name" content="Pwoly" />
                <meta property="og:title" content="Pwoly- A Tech Blog" />
                <meta property="og:type" content="Website" />
                <meta property="og:description"
                    content="Browse the updated news and dev tools from pwoly.com, the best blog platform with tons of dev news and solutions for all programming stacks." />
                <meta property="og:image" content="https://pwoly.com/assets/img/banner/1.jpg" />
                <meta property="og:url" content="https://pwoly.com/" />
            </Helmet>
            <Header cats={categories} catLoading={catLoading} />
            <div className="container-fluid">
                <div className="bannerContainer">
                    <img src={'https://picsum.photos/2000/500?grayscale'} className="" alt="image" />
                    <h1 className="h4 pt-3 top-left-header" data-animation="fadeInUp" data-delay="0.6s">
                        <Link to={`/blog/${main}/${parent}/${child} `}>{`${main}/${parent}/${child} `}</Link>
                    </h1>
                </div>
                <div
                    className="banner-content"
                    data-animation="fadeInUp"
                    data-delay="0.3s"
                >
                    <ul className="meta pt-2" data-animation="fadeInUp" data-delay="0.8s">
                        <Link to="/"><HomeIcon fontSize='13' /> Home/</Link><span>
                            <Link to={`/blog/${main}/${parent}/${child} `}>
                                {`${main}/${parent}/${child}`}
                                <i className="fas fa-long-arrow-right" />
                            </Link ></span>
                    </ul>
                    <p data-animation="fadeInUp" data-delay="1s">
                        Browse the updated news and dev tools from pwoly.com, the best blog platform with tons of dev news and solutions for all programming stacks.
                    </p>
                </div>
            </div >

            <section className="post-area with-sidebar">
                <div className="container-fluid">
                    <div className="post-area-inner">
                        {success ? < Post b={blogs} /> : <Loader />}
                        {catLoading ? <Loader /> : <Sidebar cats={categories} />}
                    </div>
                </div>
            </section>

            <Footer />
        </>
    )
}

export default BlogsByCategory