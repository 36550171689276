import axios from "axios"
import { CATEGORY_LIST_FAILURE, CATEGORY_LIST_REQUEST, CATEGORY_LIST_SUCCESS } from "../../constants/categoryConstants"
import { API_URI } from "../../constants/globalConstants"

export const getAllCategories = (power) => async (dispatch) => {
    try {
        dispatch({ type: CATEGORY_LIST_REQUEST })
        const { data } = await axios.get(`${API_URI}/api/category/get-categories`, power)
        dispatch({
            type: CATEGORY_LIST_SUCCESS,
            payload: data.categories
        })
        // console.log(data)
    } catch (error) {
        dispatch({
            type: CATEGORY_LIST_FAILURE,
            payload: error.response && error.response.data.message ?
                error.response.data.message : error.message
        })
    }
}

export const createCategoryAction = (val) => async (dispatch) => {
    try {
        dispatch({ type: "CATEGORY_CREATE_REQUEST" })

        const { data } = await axios.post(`${API_URI}/api/category/create`, val)
        console.table(val)
        dispatch({ type: "CATEGORY_CREATE_SUCCESS", payload: data })
    } catch (error) {
        dispatch({
            type: "CATEGORY_CREATE_FAILURE",
            payload: error.response && error.response.data.message ?
                error.response.data.message : error.message
        })
    }
}