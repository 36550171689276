import React from 'react'
import { Link } from 'react-router-dom'

const ParentChildSidebar = () => {
    return (
        <div className="primary-sidebar clearfix">
            <div className="sidebar-masonary row">
                <div className="col-lg-12 col-sm-6 widget popular-articles">
                    <h5 className="widget-title">Popular Articles</h5>
                    <div className="articles">
                        <div className="">
                            <div className="">
                                <h6>
                                    <Link to="/blogs/tech">- Tech </Link>
                                </h6>
                                <p>
                                    <Link to="/blogs/react-js">React JS</Link>
                                </p>
                                <p>
                                    <Link to="/blogs/unity-engine">Unity Engine</Link>
                                </p>
                                <p>
                                    <Link to="/blogs/web-dev">Web Dev</Link>
                                </p>
                                {/* <h6>
                            <Link to="/blogs/solidity">- Solidity</Link>
                        </h6> */}
                                <span className="post-date"></span>
                            </div>
                        </div>
                    </div >
                </div >
            </div >
        </div >
    )
}

export default ParentChildSidebar