import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import Loader from './Loader'

export const Header = ({ cats, catLoading }) => {
    const dispatch = useDispatch()

    const userLogin = useSelector(state => state.userLogin)
    const { userInfo } = userLogin

    const [toggle, setToggle] = useState('');
    const [search, setSearch] = useState('');

    const handle = () => {
        console.log(search)
    }

    const [mainToggle, setMainToggle] = useState(null);
    const [parentToggle, setParentToggle] = useState(null);
    const [childToggle, setChildToggle] = useState(null);

    let mainCatStyle = {
        backgroundColor: "#0d1117",
        border: "none",
        fontSize: "20px",
        textDecoration: "overline"
    }

    let parentCatStyle = {
        backgroundColor: "#0d1117",
        border: "none",
        fontSize: "15px",
        marginTop: "0px",
        marginBottom: "0px",
        paddingLeft: "25px"
    }
    let childCatStyle = {
        backgroundColor: "#0d1117",
        border: "none",
        fontSize: "15px",
        marginTop: "10px",
        marginBottom: "5px",
        paddingLeft: "35px"
    }


    const childCat = (parentCat) => {
        let childCat = []
        cats.map(cat => (
            cat.power === 3 && cat.mainCategory === mainToggle && cat.parentCategory === parentCat && (
                childCat.push(cat)
            )
        ))
        childCat.sort((a, b) => a.childCategory.localeCompare(b.childCategory))
        const child = childCat.map(cat => (
            <>
                <p style={childCatStyle}><Link to={`/blog/${cat.slug}`}>{cat.childCategory}</Link></p>
            </>
        ))
        return child
    }

    const parentCat = (mainCat) => {
        let parentsCat = []
        cats.map(cat => (
            cat.power === 2 && cat.mainCategory === mainCat && (
                parentsCat.push(cat.parentCategory)
            )
        ))
        parentsCat.sort()
        const parent = parentsCat.map(cat => (
            <>
                <button className='btn btn-primary' style={parentCatStyle} onClick={(e) => setParentToggle(cat)}>
                    - {cat}
                </button>
                <br />
                {parentToggle === cat && childCat(parentToggle)}
            </>
        ))
        return parent
    }

    const sideCat = (cats) => {
        const cat = cats.map(cat => (
            cat.power === 1 && (
                <>
                    {/* <hr style={{ margin: "0px", backgroundColor: "#8266ac89" }} /> */}
                    <button className="btn btn-dark" style={mainCatStyle} onClick={(e) => setMainToggle(cat.mainCategory)}>
                        {cat.mainCategory}
                    </button>
                    <br />
                    {mainToggle === cat.mainCategory && parentCat(mainToggle)}
                </>
            )
        ))
        return cat
    }


    return (
        <header className="sticky-header">
            <div className="container-fluid">
                <div className="d-flex align-items-center justify-content-between">
                    <div className="site-logo">
                        <Link to="/">
                            <img src="/assets/img/logo.png" alt="Pwoly blog" />
                        </Link>
                    </div>
                    <div className="header-right">
                        {/* <form className="form-inline d-flex justify-content-center md-form form-sm mt-0 ">
                            <input className="form-control form-control-sm ml-3 w-100 bg-dark text-white" type="text" placeholder="Search"
                                aria-label="Search" />
                        </form> */}
                        <div class="search-box">
                            <button class="btn-search" onClick={handle}><i class="fas fa-search"></i></button>
                            <input type="text" class="input-search" placeholder="Type to Search..." onChange={(e) => setSearch(e.target.value)} />
                        </div>
                        {/* <div className="offcanvas-panel panel-open"> */}
                        <div className={`offcanvas-panel ${toggle}`} >
                            <Link to="" className="panel-btn" onClick={() => setToggle('panel-open')}>
                                <span>
                                    <span />
                                    <span />
                                    <span />
                                </span>
                            </Link>
                            <div className="panel-overly" />
                            <div className="offcanvas-items" >
                                {/* Navbar Toggler */}
                                <Link to="" className="panel-close" onClick={() => setToggle('')}>
                                    Back <i className="fa fa-angle-right" aria-hidden="true" onClick={() => setToggle('')} />
                                </Link>
                                {catLoading ? <Loader /> : (
                                    <ul className="offcanvas-menu">
                                        {sideCat(cats)}
                                        {userInfo && (
                                            <li>
                                                <Link to="/blog/create">Create Blog</Link>
                                            </li >
                                        )}
                                    </ul >
                                )}
                                <div className="social-icons">
                                    <ul>
                                        <li>
                                            <a href="https://www.youtube.com/channel/UC_rBAnECubVMspuuA6n8npg" target="_blank" rel="noopener noreferrer">
                                                <i className="fab fa-youtube" />
                                            </a>
                                        </li>
                                    </ul>
                                </div >
                            </div >
                        </div >
                    </div >
                </div >
            </div >
        </header >
    )
}
