import React, { useEffect, useState } from 'react'

import QuillEditor from '../../../../components/editor/QuillEditor';
import { Typography, Button, Form, message } from 'antd';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { createBlogAction } from '../../../../redux/actions/blogActions';
import { createCategoryAction, getAllCategories } from '../../../../redux/actions/categoryActions';
import Loader from '../../../Layout/Loader';
import { Header } from '../../../Layout/Header';
// import { useSelector } from "react-redux";

const { Title } = Typography;

const CreatePage = () => {
    const Login = useSelector(state => state.userLogin)
    const { userInfo } = Login

    const newBlog = useSelector((state) => state.blogCreate)
    const { loading, success } = newBlog
    const category = useSelector((state) => state.getCategories,)
    const { loading: catLoading, error, categories } = category
    const categoryCreate = useSelector((state) => state.createCategory)
    const { loading: createCatLoading, success: createCatSuccess } = category

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const [title, setTitle] = useState("")
    const [image, setImage] = useState('')
    const [content, setContent] = useState("")
    const [files, setFiles] = useState([])
    const [blogMainCategory, setBlogMainCategory] = useState("")
    const [blogParentCategory, setBlogParentCategory] = useState("")
    const [blogChildCategory, setBlogChildCategory] = useState("")
    const [metaTitle, setMetaTitle] = useState("")
    const [metaDes, setMetaDes] = useState("")
    const [metaKeywords, setMetaKeywords] = useState("")

    const [isCreateCat, setIsCreateCat] = useState("");
    const [createCatName, setCreateCatName] = useState('');

    const onEditorChange = (value) => {
        setContent(value)
    }

    const onFilesChange = (files) => {
        setFiles(files)
    }

    const uploadImgHandler = (e) => {
        e.preventDefault();
        setImage(e.target.files[0])
    }

    const onSubmit = (event) => {
        event.preventDefault();
        // setContent("");
        // if (user.userData && !user.userData.isAuth) {
        //     return alert('Please Log in first');
        // }
        //Creating the Form-Data
        const form = new FormData()
        form.append('title', title)
        form.append('file', image)
        form.append('content', content)
        form.append('category', blogChildCategory)
        form.append('metaTitle', metaTitle)
        form.append('metaDes', metaDes)
        form.append('metaKeywords', metaKeywords)
        //API Call
        // console.log(form)

        dispatch(createBlogAction(form))
    }

    const createCategory = async (e, power) => {
        e.preventDefault()
        console.log(power)
        let val = {}
        if (power === 1) {
            val = {
                mainCategory: createCatName,
                power: 1
            }
        } else if (power === 2) {
            val = {
                mainCategory: blogMainCategory,
                parentCategory: createCatName,
                power: 2
            }
        } else if (power == 3) {
            val = {
                mainCategory: blogMainCategory,
                parentCategory: blogParentCategory,
                childCategory: createCatName,
                power: 3
            }
        }
        dispatch(createCategoryAction(val))
        setIsCreateCat("")
        await dispatch({ type: "CATEGORY_LIST_RESET" })
        await dispatch(getAllCategories())
    }

    useEffect(() => {
        if (!userInfo && !userInfo.isAdmin) {
            navigate('/')
        } else {
            dispatch(getAllCategories())
        }
    }, [dispatch, userInfo]);

    // console.log(isCreateCat)

    return (
        <>
            {/* <Header /> */}
            <div style={{ maxWidth: '900px', margin: '2rem auto', padding: '0 15px' }}>
                <div style={{ textAlign: 'center' }}>
                    <Title level={2} > Editor</Title>
                </div>
                {loading ? (<Loader />) : (catLoading ? (
                    <Loader />
                ) : (
                    <form onSubmit={onSubmit}>
                        <div className="row">
                            <div className="col-lg-12">
                                <h4 className="checkout-title">Blog details</h4>
                                <div className="form-group">
                                    <label htmlFor="firstName">Title*</label>
                                    <input type="text" id="firstName" value={title} onChange={(e) => setTitle(e.target.value)} />
                                </div>
                                {/* ------------Main Category----------- */}
                                <div className="form-group">
                                    <label htmlFor="state">Main Category*</label>
                                    {isCreateCat !== "main" && <select id="state" className="wide"
                                        value={blogMainCategory} onChange={(e) => setBlogMainCategory(e.target.value)}>
                                        <option value="" />
                                        {categories && categories.map(cat => (
                                            cat.power == 1 && (
                                                <>
                                                    <option key={cat._id} value={cat.mainCategory}>{cat.mainCategory}</option>
                                                </>
                                            )
                                        ))}
                                    </select>}
                                    {isCreateCat === "main" ? (
                                        <>
                                            <hr />
                                            <label htmlFor="state">Create Main Category*</label>
                                            <input value={createCatName} onChange={(e) => setCreateCatName(e.target.value)} />
                                            <button className="btn btn-secondary" onClick={(e) => createCategory(e, 1)}>
                                                Submit
                                            </button>
                                            <button className="btn btn-link" onClick={(e) => setIsCreateCat(false)}>
                                                cancel
                                            </button>
                                        </>
                                    ) : (
                                        <button className="btn btn-link" onClick={(e) => setIsCreateCat("main")}>
                                            Create Main Category
                                        </button>
                                    )}
                                </div>
                                {/* ------------Parent Category----------- */}
                                <div className="form-group">
                                    <label htmlFor="state">Parent Category*</label>
                                    {isCreateCat !== "parent" && <select id="state" className="wide"
                                        value={blogParentCategory} onChange={(e) => setBlogParentCategory(e.target.value)}>
                                        <option value="" />
                                        {categories && categories.map(cat => (
                                            cat.power === 2 && cat.mainCategory == blogMainCategory && (
                                                <>
                                                    <option key={cat._id} value={cat.parentCategory}>{cat.parentCategory}</option>
                                                </>
                                            )
                                        ))}
                                    </select>}
                                    {isCreateCat === "parent" ? (
                                        <>
                                            <hr />
                                            <label htmlFor="state">Create Parent Category*</label>
                                            <input value={createCatName} onChange={(e) => setCreateCatName(e.target.value)} />
                                            <button className="btn btn-secondary" onClick={(e) => createCategory(e, 2)}>
                                                Submit
                                            </button>
                                            <button className="btn btn-link" onClick={(e) => setIsCreateCat(false)}>
                                                cancel
                                            </button>
                                        </>
                                    ) : (
                                        <button className="btn btn-link" onClick={(e) => setIsCreateCat("parent")}>
                                            Create Parent Category
                                        </button>
                                    )}
                                </div>
                                {/* ------------Child Category----------- */}
                                <div className="form-group">
                                    <label htmlFor="state">Child Category*</label>
                                    {isCreateCat !== "child" && <select id="state" className="wide"
                                        value={blogChildCategory} onChange={(e) => setBlogChildCategory(e.target.value)}>
                                        <option value="" />
                                        {categories && categories.map(cat => (
                                            cat.power == 3 && cat.parentCategory === blogParentCategory && (
                                                <>
                                                    <option key={cat._id} value={cat._id}>{cat.childCategory}</option>
                                                </>
                                            )
                                        ))}
                                    </select>}
                                    {isCreateCat === "child" ? (
                                        <>
                                            <hr />
                                            <label htmlFor="state">Create Child Category*</label>
                                            <input value={createCatName} onChange={(e) => setCreateCatName(e.target.value)} />
                                            <button className="btn btn-secondary" onClick={(e) => createCategory(e, 3)}>
                                                Submit
                                            </button>
                                            <button className="btn btn-link" onClick={(e) => setIsCreateCat(false)}>
                                                cancel
                                            </button>
                                        </>
                                    ) : (
                                        <button className="btn btn-link" onClick={(e) => setIsCreateCat("child")}>
                                            Create Child Category
                                        </button>
                                    )}
                                </div>
                                <div className="form-group">
                                    <label htmlFor="pic">Blog image*</label>
                                    <input type="file" name="file" onChange={uploadImgHandler} />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="firstName">Meta-Title*</label>
                                    <input type="text" value={metaTitle} onChange={(e) => setMetaTitle(e.target.value)} />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="firstName">Meta-Des*</label>
                                    <input type="text" value={metaDes} onChange={(e) => setMetaDes(e.target.value)} />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="firstName">Keywords*</label>
                                    <input type="text" value={metaKeywords} onChange={(e) => setMetaKeywords(e.target.value)} />
                                </div>
                            </div>
                        </div>
                        <QuillEditor
                            placeholder={"Start Posting Something"}
                            onEditorChange={onEditorChange}
                            onFilesChange={onFilesChange}
                        />

                        <div style={{ textAlign: 'center', margin: '2rem', }}>
                            <Button
                                size="large"
                                htmlType="submit"
                                className=""
                                onSubmit={onSubmit}
                            >
                                Submit
                            </Button>
                        </div>
                    </form>
                ))}
            </div>
        </>
    )
}

export default CreatePage
