import React from 'react'
import { Link } from 'react-router-dom'
import Masonry from 'react-masonry-css'

const Post = ({ b }) => {
    const breakpointColumnsObj = {
        default: 3,
        1100: 3,
        700: 2,
        500: 1
    };
    // console.log(b)
    return (
        <>
            {
                <div className="pt-5">
                    <Masonry
                        breakpointCols={breakpointColumnsObj}
                        className="my-masonry-grid"
                        columnClassName="my-masonry-grid_column"
                    >
                        {
                            b && b.map((blog) =>
                                < div className="" >
                                    <div className="entry-post">
                                        <div className="entry-thumbnail">
                                            {blog.file && <Link to={`/blog/${blog.slug}`}>
                                                <img src={`${blog.file}`} className="shadowImg" alt="Image" />
                                            </Link>}
                                        </div>
                                        <div className="entry-content pt-3">
                                            <h6 className="">
                                                <Link to={`/blog/${blog.slug}`}>{blog.title}</Link>
                                            </h6>
                                            <Link to={`/blog/${blog.slug}`} className="read-more">
                                                Pwoly More <i className="fas fa-long-arrow-right" />
                                            </Link>
                                        </div>
                                    </div>
                                </div >
                            )
                        }
                    </Masonry>
                </div>
            }
        </>
    )
}

export default Post