import axios from "axios"
import { BLOGs_BY_CAT_FAIL, BLOGs_BY_CAT_REQUEST, BLOGs_BY_CAT_SUCCESS, BLOG_CREATE_FAIL, BLOG_CREATE_REQUEST, BLOG_CREATE_SUCCESS, BLOG_DETAIL_FAIL, BLOG_DETAIL_REQUEST, BLOG_DETAIL_SUCCESS, BLOG_LIST_FAIL, BLOG_LIST_REQUEST, BLOG_LIST_SUCCESS, BLOG_UPDATE_FAIL, BLOG_UPDATE_REQUEST, BLOG_UPDATE_SUCCESS } from "../../constants/blogConstants"
import { API_URI } from "../../constants/globalConstants"


// ---------------------------PUBLIC ACCESS------------------------

//GET ALL THE BLOGS
export const listBlogs = () => async (dispatch) => {
    try {
        dispatch({ type: BLOG_LIST_REQUEST })
        const { data } = await axios.get(`${API_URI}/api/blog/all/getHomeBlogs`)
        dispatch({ type: BLOG_LIST_SUCCESS, payload: data })
    } catch (error) {
        dispatch({
            type: BLOG_LIST_FAIL,
            payload: error.response && error.response.data.message ?
                error.response.data.message : error.message
        })
    }
}

// GET Blogs by Category
export const getBlogsByCategory = (category) => async (dispatch) => {
    try {
        dispatch({ type: BLOGs_BY_CAT_REQUEST })
        const { data } = await axios.get(`${API_URI}/api/blog//blogsByCategory/${category}`)
        dispatch({ type: BLOGs_BY_CAT_SUCCESS, payload: data })
    } catch (error) {
        dispatch({
            type: BLOGs_BY_CAT_FAIL,
            payload: error.response && error.response.data.message ?
                error.response.data.message : error.message
        })
    }
}

//GET BLOG-DETAIL
export const blogDetailAction = (slug) => async (dispatch) => {
    try {
        dispatch({ type: BLOG_DETAIL_REQUEST })
        const { data } = await axios.get(`${API_URI}/api/blog/${slug}`)
        dispatch({ type: BLOG_DETAIL_SUCCESS, payload: data })
    } catch (error) {
        dispatch({
            type: BLOG_DETAIL_FAIL,
            payload: error.response && error.response.data.message ?
                error.response.data.message : error.message
        })
    }
}

//GET BLOG-DETAIL
export const blogsByChildCategory = (child) => async (dispatch) => {
    try {
        dispatch({ type: 'BLOGS_BY_CHILD_CAT_REQUEST' })
        const { data } = await axios.post(`${API_URI}/api/blog/blogByChildCategory`, child)
        dispatch({ type: 'BLOGS_BY_CHILD_CAT_SUCCESS', payload: data })
    } catch (error) {
        dispatch({
            type: 'BLOGS_BY_CHILD_CAT_FAIL',
            payload: error.response && error.response.data.message ?
                error.response.data.message : error.message
        })
    }
}



// ---------------------------ADMIN ACCESS------------------------

//CREATE BLOG 
export const createBlogAction = (form) => async (dispatch, getState) => {
    try {
        dispatch({ type: BLOG_CREATE_REQUEST })
        const { userLogin: { userInfo } } = getState()
        //passing the token
        const config = {
            headers: {
                Authorization: `Bearer ${userInfo.token}`
            }
        }
        const { data } = await axios.post(`${API_URI}/api/blog/create`, form, config)
        console.table(form)
        dispatch({ type: BLOG_CREATE_SUCCESS, payload: data })
    } catch (error) {
        dispatch({
            type: BLOG_CREATE_FAIL,
            payload: error.response && error.response.data.message ?
                error.response.data.message : error.message
        })
    }
}

//UPDATE BLOG
export const updateBlogAction = (blog) => async (dispatch, getState) => {
    try {
        dispatch({ type: BLOG_UPDATE_REQUEST })
        const { userLogin: { userInfo } } = getState()
        //passing the token
        const config = {
            headers: {
                Authorization: `Bearer ${userInfo.token}`
            }
        }
        const { data } = await axios.put(`${API_URI}/api/blog/editBlog`, blog, config)
        dispatch({ type: BLOG_UPDATE_SUCCESS, payload: data })
    } catch (error) {
        dispatch({
            type: BLOG_UPDATE_FAIL,
            payload: error.response && error.response.data.message ?
                error.response.data.message : error.message
        })
    }
}