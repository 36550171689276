export const BLOG_LIST_REQUEST = 'BLOG_LIST_REQUEST'
export const BLOG_LIST_SUCCESS = 'BLOG_LIST_SUCCESS'
export const BLOG_LIST_FAIL = 'BLOG_LIST_FAIL'

export const BLOGs_BY_CAT_REQUEST = 'BLOGs_BY_CAT_REQUEST'
export const BLOGs_BY_CAT_SUCCESS = 'BLOGs_BY_CAT_SUCCESS'
export const BLOGs_BY_CAT_FAIL = 'BLOGs_BY_CAT_FAIL'

export const BLOG_DETAIL_REQUEST = 'BLOG_DETAIL_REQUEST'
export const BLOG_DETAIL_SUCCESS = 'BLOG_DETAIL_SUCCESS'
export const BLOG_DETAIL_FAIL = 'BLOG_DETAIL_FAIL'

export const BLOG_CREATE_REQUEST = 'BLOG_CREATE_REQUEST'
export const BLOG_CREATE_SUCCESS = 'BLOG_CREATE_SUCCESS'
export const BLOG_CREATE_FAIL = 'BLOG_CREATE_FAIL'

export const BLOG_UPDATE_REQUEST = 'BLOG_UPDATE_REQUEST'
export const BLOG_UPDATE_SUCCESS = 'BLOG_UPDATE_SUCCESS'
export const BLOG_UPDATE_FAIL = 'BLOG_UPDATE_FAIL'