import React, { useState, useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { useDispatch, useSelector } from 'react-redux'
import Masonry from 'react-masonry-css'

import { Header } from '../../components/Layout/Header'
import { listBlogs } from '../../redux/actions/blogActions'
import Banner from '../Layout/Banner'
import Footer from '../Layout/Footer'
import Loader from '../Layout/Loader'
import Post from '../Layout/Post'
import Sidebar from '../Layout/Sidebar'
import { getAllCategories } from '../../redux/actions/categoryActions'

const Home = () => {
    const dispatch = useDispatch()

    const blogList = useSelector(state => state.blogList)
    const { loading, error, blogs } = blogList
    const getCat = useSelector(state => state.getCategories)
    const { loading: catLoading, categories } = getCat

    //fetching the data
    useEffect(() => {
        dispatch(listBlogs())
        dispatch(getAllCategories({ "power": 1 }))
    }, []);

    return (
        <>
            <Helmet>
                <meta charset="UTF-8" />
                <meta http-equiv="X-UA-Compatible" content="IE=edge" />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <meta name="format-detection" content="telephone=no" />
                <meta name='robots' content='index, follow, max-image-preview:large, max-snippet:-1, max-video-preview:-1' />
                <link rel="canonical" href="https://pwoly.com/" />
                <meta property="og:locale" content="en_US" />
                <meta property="og:type" content="website" />

                <title>Pwoly- A Tech Blog</title>
                <meta name="description" content="Browse the updated news and dev tools from pwoly.com, the best blog platform with tons of dev news and solutions for all programming stacks." />
                <meta name="keywords" content="programming news, programmers blog, blogs for programmers, best programming blog, dev blogs, tech news" />
                <meta property="og:title" content="Pwoly- A Tech Blog" />
                <meta property="og:description"
                    content="Browse the updated news and dev tools from pwoly.com, the best blog platform with tons of dev news and solutions for all programming stacks." />
                <meta property="og:type" content="Website" />
                <meta property="og:url" content="https://pwoly.com/" />
                <meta property="og:image" content="https://pwoly.com/assets/img/banner/1.jpg" />
                <meta property="og:image:width" content="1280" />
                <meta property="og:image:height" content="720" />

                <meta name="twitter:title" content="Pwoly- A Tech Blog" />
                <meta name="twitter:description"
                    content="Browse the updated news and dev tools from pwoly.com, the best blog platform with tons of dev news and solutions for all programming stacks." />
                <meta name="twitter:image" content="https://pwoly.com/assets/img/banner/1.jpg" />
                <meta name="twitter:card" content="Website" />

                <meta property="og:site_name" content="Pwoly" />
                <meta property="og:title" content="Pwoly- A Tech Blog" />
                <meta property="og:type" content="Website" />
                <meta property="og:description"
                    content="Browse the updated news and dev tools from pwoly.com, the best blog platform with tons of dev news and solutions for all programming stacks." />
                <meta property="og:image" content="https://pwoly.com/assets/img/banner/1.jpg" />
                <meta property="og:url" content="https://pwoly.com/" />

            </Helmet>
            <Header cats={categories} catLoading={catLoading} />
            <Banner />

            <section className="post-area with-sidebar">
                <div className="container-fluid">
                    <div className="post-area-inner">
                        {loading && catLoading ? (
                            <Loader />
                        ) : (
                            <>
                                <Post b={blogs.newest} />
                                {categories && <Sidebar cats={categories} />}
                            </>
                        )}
                    </div>
                </div>
            </section>

            <Footer />
        </>
    )
}

export default Home