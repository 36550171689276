import React, { Suspense } from 'react';
import { BrowserRouter, Route, Routes, Link } from 'react-router-dom'
import Home from '../components/pages/Home'
import SignIn from './pages/auth/SignIn';
import Blogdetail from './pages/Blogdetail';
import BlogsByReactJS from './pages/BlogsByReactJS';
import BlogsBySolidity from './pages/BlogsBySolidity';
import BlogsByTech from './pages/BlogsByTech';
import BlogsByUnity from './pages/BlogsByCategory';
import BlogsByWebDev from './pages/BlogsByWebDev';
import CreatePage from './views/BlogPage/Section.js/CreatePage';
import EditPage from './views/BlogPage/Section.js/EditPage';
import BlogsByCategory from './pages/BlogsByCategory';

// pages for this product


function App() {
  return (
    <BrowserRouter>
      <Routes>
        {/* Public Routes */}
        <Route path='/*' element={<Home />} />
        <Route path='/blog/:main/:parent/:child/:slug' element={<Blogdetail />} />
        <Route path='/blog/:main/:parent/:child' element={<BlogsByCategory />} />
        <Route path='/blogs/react-js' element={<BlogsByReactJS />} />
        <Route path='/blogs/Web-dev' element={<BlogsByWebDev />} />
        <Route path='/blogs/solidity' element={<BlogsBySolidity />} />
        <Route path='/blogs/tech' element={<BlogsByTech />} />
        {/* Admin Routes */}
        <Route path='/blog/create' element={<CreatePage />} />
        <Route path='/edit-blog/:main/:parent/:child/:slug' element={<EditPage />} />
        <Route path='/sign-in' element={<SignIn />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
