import { applyMiddleware, combineReducers, createStore } from 'redux'
import thunk from 'redux-thunk'
import { composeWithDevTools } from 'redux-devtools-extension'
import { blogCreateReducer, blogDetailReducer, blogListReducer, blogsByCategoryReducer, blogsByChildCatReducer, blogUpdateReducer } from './redux/reducers/blogReducers'
import { createCategoryReducer, getCategoriesReducer } from './redux/reducers/categoryReducers'
import { userLoginReducer } from './redux/reducers/userReducers'


const reducer = combineReducers({
    blogDetail: blogDetailReducer,
    blogList: blogListReducer,
    blogsByCategory: blogsByCategoryReducer,
    blogCreate: blogCreateReducer,
    blogsByChildCategory: blogsByChildCatReducer,
    //category
    getCategories: getCategoriesReducer,
    blogUpdate: blogUpdateReducer,
    createCategory: createCategoryReducer,
    //User
    userLogin: userLoginReducer
})

//Getting the data from local storage
const userInfoFromStorage = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')) : null

const initialState = {
    userLogin: { userInfo: userInfoFromStorage },
}

const middleware = [thunk]

const store = createStore(reducer, initialState, composeWithDevTools(applyMiddleware(...middleware)))

export default store