import React, { useState, useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { Link, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { blogDetailAction } from '../../redux/actions/blogActions'

import Footer from '../Layout/Footer'
import { Header } from '../Layout/Header'
import Loader from '../Layout/Loader'
import { getAllCategories } from '../../redux/actions/categoryActions'

const Blogdetail = () => {
    const dispatch = useDispatch()

    const { main, parent, child, slug } = useParams()

    const blogDetail = useSelector(state => state.blogDetail)
    const { loading, blog, error } = blogDetail
    const getCat = useSelector(state => state.getCategories)
    const { loading: catLoading, categories } = getCat


    const [currentURL, setCurrentURL] = useState("");


    useEffect(() => {
        dispatch(blogDetailAction(`${main}/${parent}/${child}/${slug}`))
        dispatch(getAllCategories({ "power": 1 }))
        setCurrentURL(window.location.href)

        window.scrollTo(0, 0)
    }, [dispatch, slug])

    return (
        <>
            <Helmet>
                <meta charset="UTF-8" />
                <meta http-equiv="X-UA-Compatible" content="IE=edge" />
                <meta name="format-detection" content="telephone=no" />
                <meta name='robots' content='index, follow, max-image-preview:large, max-snippet:-1, max-video-preview:-1' />
                <link rel="canonical" href={currentURL} />
                <meta property="og:locale" content="en_US" />
                <meta property="og:type" content="website" />

                <title>{blog.metaTitle}</title>
                <meta name="description" content={blog.metaDes} />
                <meta name="keywords" content={blog.metaKeywords} />
                <meta property="og:title" content={blog.title} />
                <meta property="og:description"
                    content={blog.metaDes} />
                <meta property="og:type" content="Website" />
                <meta property="og:url" content={currentURL} />
                <meta property="og:image" content={blog.file} />
                <meta property="og:image:width" content="1280" />
                <meta property="og:image:height" content="720" />

                <meta name="twitter:title" content={blog.title} />
                <meta name="twitter:description"
                    content={blog.metaDes} />
                <meta name="twitter:image" content={blog.file} />
                <meta name="twitter:card" content="Website" />

                <meta property="og:site_name" content="Pwoly" />
                <meta property="og:title" content={blog.title} />
                <meta property="og:type" content="Website" />
                <meta property="og:description"
                    content={blog.metaDes} />
                <meta property="og:image" content={blog.file} />
                <meta property="og:url" content={currentURL} />
            </Helmet>
            <Header cats={categories} catLoading={catLoading} />
            {
                loading ? (
                    <Loader />
                ) : (
                    <section className="post-details-area">
                        <div className="container container-1000">
                            <div className="post-details">
                                <div className="entry-header">
                                    <h2 className="title">
                                        {blog.title}
                                    </h2>
                                    <ul className="post-meta">
                                        <li>
                                            <Link to="/blogs/tech">Tech,</Link>
                                            <Link to="/blogs/react-js">React JS,</Link>
                                            <Link to="/blogs/unity-engine">Unity Engine,</Link>
                                            <Link to="/blogs/web-dev">Web Dev</Link>
                                        </li>
                                    </ul>
                                </div>
                                {blog.file &&
                                    <div className="entry-media text-center">
                                        <img src={blog.file} className="shadowImg" alt="image" />
                                    </div>}
                                <div className="entry-content">
                                    <div dangerouslySetInnerHTML={{ __html: blog.content }} />
                                </div>
                            </div >
                        </div >
                    </section >
                )}
            <Footer />
        </>
    )
}

export default Blogdetail