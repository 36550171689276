import React from 'react'
import { MutatingDots } from 'react-loader-spinner'

const Loader = () => {
    return (
        <div class="container">
            <div class="row">
                <div class="w-60 mx-auto">
                    <MutatingDots
                        color="#8b949e"
                        secondaryColor="#8266ac"
                        height={100}
                        width={100}
                        style={{
                            width: '100px',
                            height: '100px',
                            margin: 'auto',
                            display: 'block',
                        }}
                    />
                </div>
            </div>
        </div>
    )
}

export default Loader