import { BLOGs_BY_CAT_FAIL, BLOGs_BY_CAT_REQUEST, BLOGs_BY_CAT_SUCCESS, BLOG_CREATE_FAIL, BLOG_CREATE_REQUEST, BLOG_CREATE_SUCCESS, BLOG_DETAIL_FAIL, BLOG_DETAIL_REQUEST, BLOG_DETAIL_SUCCESS, BLOG_LIST_FAIL, BLOG_LIST_REQUEST, BLOG_LIST_SUCCESS, BLOG_UPDATE_FAIL, BLOG_UPDATE_REQUEST, BLOG_UPDATE_SUCCESS } from "../../constants/blogConstants";


// ---------------------------PUBLIC ACCESS------------------------

export const blogListReducer = (state = { blogs: [] }, action) => {
    switch (action.type) {
        case BLOG_LIST_REQUEST:
            return { loading: true, blogs: [] }
        case BLOG_LIST_SUCCESS:
            return { loading: false, blogs: action.payload.blogs }
        case BLOG_LIST_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state;
    }
}

export const blogsByCategoryReducer = (state = { blogs: [] }, action) => {
    switch (action.type) {
        case BLOGs_BY_CAT_REQUEST:
            return { loading: true, blogs: [] }
        case BLOGs_BY_CAT_SUCCESS:
            return { loading: false, blogs: action.payload.blogs }
        case BLOGs_BY_CAT_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state;
    }
}

export const blogDetailReducer = (state = { blog: {} }, action) => {
    switch (action.type) {
        case BLOG_DETAIL_REQUEST:
            return { loading: true, blog: {} }
        case BLOG_DETAIL_SUCCESS:
            return { loading: false, blog: action.payload, success: true }
        case BLOG_DETAIL_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state;
    }
}

export const blogsByChildCatReducer = (state = { blogs: {} }, action) => {
    switch (action.type) {
        case 'BLOGS_BY_CHILD_CAT_REQUEST':
            return { loading: true, blogs: {} }
        case 'BLOGS_BY_CHILD_CAT_SUCCESS':
            return { loading: false, blogs: action.payload, success: true }
        case 'BLOGS_BY_CHILD_CAT_FAIL':
            return { loading: false, error: action.payload }
        case 'BLOGS_BY_CHILD_CAT_RESET':
            return {}
        default:
            return state;
    }
}

// ---------------------------ADMIN ACCESS------------------------

export const blogCreateReducer = (state = {}, action) => {
    switch (action.type) {
        case BLOG_CREATE_REQUEST:
            return { loading: true, }
        case BLOG_CREATE_SUCCESS:
            return { loading: false, success: true }
        case BLOG_CREATE_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state;
    }
}

export const blogUpdateReducer = (state = {}, action) => {
    switch (action.type) {
        case BLOG_UPDATE_REQUEST:
            return { loading: true, }
        case BLOG_UPDATE_SUCCESS:
            return { loading: false, success: true, blog: action.payload }
        case BLOG_UPDATE_FAIL:
            return { loading: false, error: action.payload }
        case 'BLOG_UPDATE_RESET':
            return {}
        default:
            return state;
    }
}