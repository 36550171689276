import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { login } from '../../../redux/actions/userActions'
import Loader from '../../Layout/Loader'

const SignIn = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')

    const userLogin = useSelector(state => state.userLogin)
    const { loading, error, userInfo } = userLogin

    useEffect(() => {
        if (userInfo) {
            navigate('/')
        }
    }, [userInfo])

    const submitHandler = (e) => {
        e.preventDefault()
        dispatch(login(email, password))
    }

    return (
        <section className="shopping-login-page">
            <div className="container">
                <div className="login-wrapper">
                    <div className="row no-gutters">
                        <div className="col-lg-12">
                            <div className="login-box">
                                <div className="form-title">
                                    <h3>Login</h3>
                                    {error && <p classname="text-danger">{error}</p>}
                                    <p>I'm an existing customer and would like to login.</p>
                                </div>
                                {loading ?
                                    <Loader /> :
                                    (<form className="login-form" onSubmit={submitHandler}>
                                        <p>
                                            <label htmlFor="username">
                                                Username or email address<span className="required">*</span>
                                            </label>
                                            <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} />
                                        </p>
                                        <p>
                                            <label htmlFor="password">
                                                Password<span className="required">*</span>
                                            </label>
                                            <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} />
                                        </p>
                                        <p className="text-center">
                                            <button type="submit" className="form-btn">
                                                Login
                                            </button>
                                        </p>
                                    </form>)
                                }

                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </section>

    )
}

export default SignIn