import React, { useState, useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { getBlogsByCategory } from '../../redux/actions/blogActions'
import Footer from '../Layout/Footer'
import Loader from '../Layout/Loader'
import { Header } from '../Layout/Header'
import Post from '../Layout/Post'
import Sidebar from '../Layout/Sidebar'
import ParentChildSidebar from '../Layout/ParentChildSidebar'

const BlogsByReactJS = () => {
    const dispatch = useDispatch()
    const category = "react-js"
    const blogsByCat = useSelector(state => state.blogsByCategory)
    const { loading, blogs, error } = blogsByCat

    useEffect(() => {
        dispatch(getBlogsByCategory("62fca8ca532436d9aff44760"))
        window.scrollTo(0, 0)
    }, [])

    return (
        <>
            <Helmet>
                <meta charset="UTF-8" />
                <meta http-equiv="X-UA-Compatible" content="IE=edge" />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <meta name="format-detection" content="telephone=no" />
                <meta name='robots' content='index, follow, max-image-preview:large, max-snippet:-1, max-video-preview:-1' />
                <link rel="canonical" href="https://pwoly.com/" />
                <meta property="og:locale" content="en_US" />
                <meta property="og:type" content="website" />

                <title>Pwoly- A Tech Blog</title>
                <meta name="description" content="Browse the updated news and dev tools from pwoly.com, the best blog platform with tons of dev news and solutions for all programming stacks." />
                <meta name="keywords" content="programming news, programmers blog, blogs for programmers, best programming blog, dev blogs, tech news" />
                <meta property="og:title" content="Pwoly- A Tech Blog" />
                <meta property="og:description"
                    content="Browse the updated news and dev tools from pwoly.com, the best blog platform with tons of dev news and solutions for all programming stacks." />
                <meta property="og:type" content="Website" />
                <meta property="og:url" content="https://pwoly.com/" />
                <meta property="og:image" content="https://pwoly.com/assets/img/banner/1.jpg" />
                <meta property="og:image:width" content="1280" />
                <meta property="og:image:height" content="720" />

                <meta name="twitter:title" content="Pwoly- A Tech Blog" />
                <meta name="twitter:description"
                    content="Browse the updated news and dev tools from pwoly.com, the best blog platform with tons of dev news and solutions for all programming stacks." />
                <meta name="twitter:image" content="https://pwoly.com/assets/img/banner/1.jpg" />
                <meta name="twitter:card" content="Website" />

                <meta property="og:site_name" content="Pwoly" />
                <meta property="og:title" content="Pwoly- A Tech Blog" />
                <meta property="og:type" content="Website" />
                <meta property="og:description"
                    content="Browse the updated news and dev tools from pwoly.com, the best blog platform with tons of dev news and solutions for all programming stacks." />
                <meta property="og:image" content="https://pwoly.com/assets/img/banner/1.jpg" />
                <meta property="og:url" content="https://pwoly.com/" />
            </Helmet>
            <Header />
            <div className="container-fluid">
                <div className="">
                    <img src={'https://pwoli-blog.s3.us-west-2.amazonaws.com/category-images/pwoly-react-JS-blog.jpg'} className="" alt="image" />
                </div>
                <div
                    className="banner-content"
                    data-animation="fadeInUp"
                    data-delay="0.3s"
                >
                    <h1 className="title pt-3" data-animation="fadeInUp" data-delay="0.6s">
                        <Link to={`/blogs/${category}`}>React Js  </Link>
                    </h1>
                    <ul className="meta" data-animation="fadeInUp" data-delay="0.8s">
                        <Link to="/">home/</Link><span>
                            <Link to={`/blogs/${category}`}>
                                react-js
                                <i className="fas fa-long-arrow-right" />
                            </Link ></span>
                    </ul>
                    <p data-animation="fadeInUp" data-delay="1s">
                        Browse the updated news and dev tools from pwoly.com, the best blog platform with tons of dev news and solutions for all programming stacks.
                    </p>
                </div>
            </div >

            <section className="post-area with-sidebar">
                <div className="container-fluid">
                    <div className="post-area-inner">
                        {loading ? (
                            <Loader />
                        ) : (
                            <>
                                < Post b={blogs} />
                                <ParentChildSidebar />
                                <div className="col-12 pb-5">
                                    <div className="text-center">
                                        <Link to={`/blogs/${category}`} className="load-more-btn">
                                            Pwoly More
                                        </Link>
                                    </div>
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </section>

            <Footer />
        </>
    )
}

export default BlogsByReactJS