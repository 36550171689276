import React from 'react'
import { Link } from 'react-router-dom'

const Footer = () => {
    return (
        <footer>
            <div className="footer-widgets-area">
                <div className="container container-1360">
                    <div className="row">
                        {/* <div className="col-lg-6 col-sm-6">
                            <div className="widget nav-widget">
                                <h4 className="widget-title">Categories</h4>
                                <div className="row">
                                    <div className="col-6">
                                        <ul>
                                            <li>
                                                <Link to="/blogs/tech" target="_blank" rel="noopener noreferrer">Tech</Link>
                                            </li>
                                            <li>
                                                <Link to="/blogs/react-js" target="_blank" rel="noopener noreferrer">React JS</Link>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="col-6">
                                        <ul>
                                            <li>
                                                <Link to="/blogs/unity-engine" target="_blank" rel="noopener noreferrer">Unity Engine</Link>
                                            </li>
                                            <li>
                                                <Link to="/blogs/web-dev" target="_blank" rel="noopener noreferrer">Web Dev</Link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                        <div className="col-lg-4 col-sm-6 ml-auto">
                            <div className="widget newsletter-widget">
                                <h4 className="widget-title">Pwoly.com </h4>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
            <div className="footer-copyright-area">
                <div className="container container-1360">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-12">
                            <div className="social-links">
                                <ul>
                                    <li className="title">Follow Me</li>
                                    <li>
                                        <a href="https://www.youtube.com/channel/UC_rBAnECubVMspuuA6n8npg" target="_blank"
                                            rel="noopener noreferrer">
                                            Youtube
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-6 col-12">
                            <div className="copyright-text text-lg-right">
                                <p>
                                    <span>Powered by &nbsp;</span>
                                    <a href="https://odidor.co/" target="_blank"
                                        rel="noopener noreferrer">
                                        Odidor
                                    </a>
                                </p>
                            </div>
                        </div>
                    </div>
                </div >
            </div >
        </footer >
    )
}

export default Footer