import React, { useState, useEffect } from 'react'
import { Header } from '../../../Layout/Header'
import { useNavigate } from 'react-router-dom';

import QuillEditor from '../../../../components/editor/QuillEditor';
import { Button } from 'antd';
import Loader from '../../../Layout/Loader';
import { blogDetailAction, updateBlogAction } from '../../../../redux/actions/blogActions';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom'


const EditPage = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { main, parent, child, slug } = useParams()


    //REDUX Editing the blog
    const updateBlog = useSelector((state) => state.blogUpdate)
    const { loading: updateLoading, success: updateSuccess } = updateBlog

    const Login = useSelector(state => state.userLogin)
    const { userInfo } = Login

    //REDUX Getting the Category
    const blogDetail = useSelector(state => state.blogDetail)
    const { loading, blog, success, error } = blogDetail

    const [title, setTitle] = useState("")
    const [content, setContent] = useState("");
    const [blogSlug, setBlogSlug] = useState("");
    const [blogCategory, setBlogCategory] = useState("");
    const [blogId, setBlogId] = useState("");
    const [files, setFiles] = useState([])
    const [metaTitle, setMetaTitle] = useState("")
    const [metaDes, setMetaDes] = useState("")
    const [metaKeywords, setMetaKeywords] = useState("")

    const onEditorChange = (value) => {
        setContent(value)
    }

    const onFilesChange = (files) => {
        setFiles(files)
    }

    useEffect(() => {
        window.scrollTo(0, 0)
        if (!userInfo && !userInfo.isAdmin) {
            navigate('/')
        } else {
            if (slug !== blogSlug && title !== blog.title) {
                dispatch(blogDetailAction(`${main}/${parent}/${child}/${slug}`))
            }
        }
    }, [])

    useEffect(() => {
        if (success) {
            setContent(blog.content)
            setTitle(blog.title)
            // setBlogCategory(blog.category)
            setBlogId(blog._id)
            setBlogSlug(blog.slug)
            setMetaTitle(blog.metaTitle)
            setMetaDes(blog.metaDes)
            setMetaKeywords(blog.metaKeywords)
        }
    }, [success]);


    const onSubmit = (event) => {
        event.preventDefault();
        // setContent("");
        // if (user.use && !user.use.isAuth) {
        //     return alert('Please Log in first');
        // }
        //Creating the Form
        // const form = new For()
        // form.append('title', title)
        // form.append('content', content)
        // form.append('category', blogCategory)
        //API Call
        dispatch(updateBlogAction({
            _id: blog._id,
            title,
            content,
            metaTitle,
            metaDes,
            metaKeywords,
        }))
    }

    // console.log(blog.title)

    return (
        <>
            {/* <Header /> */}
            <div style={{ maxWidth: '900px', margin: '2rem auto', padding: '0 15px' }}>
                <div style={{ textAlign: 'center' }}>
                    <h2 level={2} > {slug}</h2>
                </div>
                {!success ? (
                    <Loader />
                ) : (
                    <form onSubmit={onSubmit}>
                        <div className="row">
                            <div className="col-lg-12">
                                <h4 className="checkout-title">Blog details</h4>
                                <div className="form-group">
                                    <label htmlFor="firstName">Title*</label>
                                    <input type="text" id="firstName" value={title} onChange={(e) => setTitle(e.target.value)} />
                                </div>
                                {/* <div className="form-group">
                                    <label htmlFor="state">Category*</label>
                                    <select id="state" className="wide"
                                        value={blogCategory} onChange={(e) => setBlogCategory(e.target.value)}>
                                        {categories.map(cat =>
                                            <option value={cat._id}>{cat.category}</option>
                                        )}
                                    </select>
                                </div> */}
                                <div className="form-group">
                                    <label htmlFor="firstName">Meta-Title*</label>
                                    <input type="text" value={metaTitle} onChange={(e) => setMetaTitle(e.target.value)} />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="firstName">Meta-Des*</label>
                                    <input type="text" value={metaDes} onChange={(e) => setMetaDes(e.target.value)} />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="firstName">Keywords*</label>
                                    <input type="text" value={metaKeywords} onChange={(e) => setMetaKeywords(e.target.value)} />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="pic">Blog image*</label>
                                    {/* <input type="file" name="file" onChange={uploadImgHandler} /> */}
                                </div>
                            </div>
                        </div>
                        <QuillEditor
                            placeholder={"Start Posting Something"}
                            onEditorChange={onEditorChange}
                            onFilesChange={onFilesChange}
                        />

                        <div style={{ textAlign: 'center', margin: '2rem', }}>
                            <Button
                                size="large"
                                htmlType="submit"
                                className=""
                                onSubmit={onSubmit}
                            >
                                Submit
                            </Button>
                        </div>
                    </form>
                )}
            </div>
        </>
    )
}

export default EditPage


