import React from 'react'
import { Link } from 'react-router-dom'

const Banner = () => {
    return (
        <section className="banner-section">
            <div className="banner-slider">
                <div className="sinlge-banner">
                    <div className="banner-wrapper">
                        <div
                            className="banner-bg"
                            style={{ backgroundImage: "url(https://pwoli-blog.s3.us-west-2.amazonaws.com/category-images/pwoly.png" }}
                        />
                        <div
                            className="banner-content"
                            data-animation="fadeInUp"
                            data-delay="0.3s"
                        >
                            <h1 className="title" data-animation="fadeInUp" data-delay="0.6s">
                                <Link to="#">Pwoly.com - <br />Find the tech solutions </Link>
                            </h1>
                            <ul className="meta" data-animation="fadeInUp" data-delay="0.8s">
                                <li>
                                    <Link to="#">By - p4programmer</Link>
                                </li>
                            </ul>
                            <p data-animation="fadeInUp" data-delay="1s">
                                Browse the updated news and dev tools from pwoly.com, the best blog platform with tons of dev news and solutions for all programming stacks.
                            </p>
                            {/* <a
                                href="blog-details.html"
                                className="read-more"
                                data-animation="fadeInUp"
                                data-delay="1.2s"
                            >
                                Read More <i className="fas fa-long-arrow-right" />
                            </Link> */}
                        </div>
                    </div>
                </div>
            </div >
            <div className="banner-nav" />
        </section >
    )
}

export default Banner