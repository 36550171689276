import { CATEGORY_LIST_FAILURE, CATEGORY_LIST_REQUEST, CATEGORY_LIST_SUCCESS } from "../../constants/categoryConstants"

export const getCategoriesReducer = (state = { categories: [], loading: false }, action) => {
    switch (action.type) {
        case CATEGORY_LIST_REQUEST:
            return { loading: true, categories: [] }
        case CATEGORY_LIST_SUCCESS:
            return { loading: false, categories: action.payload }
        case CATEGORY_LIST_FAILURE:
            return { loading: false, error: action.payload }
        case "CATEGORY_LIST_RESET":
            return {}
        default:
            return state
    }
}

export const createCategoryReducer = (state = { categories: [], loading: false }, action) => {
    switch (action.type) {
        case "CATEGORY_CREATE_REQUEST":
            return { loading: true, categories: [] }
        case "CATEGORY_CREATE_SUCCESS":
            return { loading: false, success: true }
        case "CATEGORY_CREATE_FAILURE":
            return { loading: false, error: action.payload }
        default:
            return state
    }
}